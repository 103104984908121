const data = {
  email: 'an.gobert@gmail.com',

  name: 'Exposition de Charleville-Mézières',
  artists: 'Collectif d\'artistes du Hainaut Belge et Mr André GOBERT',
  organization: 'Jean-Paul BEUGNIES',
  dates: '15 au 20 octobre 2021',
  hours: 'ouvert de 12h à 18h',
  maps: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d322.0867782574419!2d4.723332881384404!3d49.773008507961954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ea0e09726d125d%3A0x2a0ec862ed6315c!2sGalerie%20Lebon!5e0!3m2!1sfr!2slu!4v1633869645853!5m2!1sfr!2slu" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>',
  location: '<strong>Galerie LEBON</strong><br/><br/>23 rue du Petit-Bois,<br/>08000 Charleville-Mézières,<br/>France'



};

export default data
