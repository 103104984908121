import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import contactData from './../data/contact'
import {Col, Grid, Row} from "react-flexbox-grid";
import ReactHtmlParser from "react-html-parser";

class Contact extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this,'props.data.site.siteMetadata.description');

    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'fr' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${siteTitle} | Contact`}
        />

          <h2>Contact</h2>
          <br />
          <h3 className={'galerieViewAuthor'}>{contactData.name}</h3>
          <h3 className={'galerieViewTitle'}>{contactData.dates}</h3>
          <br />
          <br />

          <Grid fluid>
              <Row>
                  <Col xs={12} sm={12} md={12} lg={6} key={'contactExpoDescription'}>
                      <strong>Heures d'ouvertures :</strong> {ReactHtmlParser(contactData.hours)}<br/>
                      <strong>Artistes :</strong> {contactData.artists}<br/>
                      <strong>Organisation :</strong> {contactData.organization}
                      <br/><br/><br/><br/>
                      <p style={{textAlign: 'center'}}>{ReactHtmlParser(contactData.location)}</p>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6} key={'contactExpoMap'}>
                      {ReactHtmlParser(contactData.maps)}
                  </Col>
              </Row>
          </Grid>

      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
